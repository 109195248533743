<template>
    <div class="aops-subscription-detail">
        <div class="aop-back" @click="back">
            <i class="iconfont aop-back-icon">&#xe647;</i><span>返回</span>
        </div>
        <div class="aop-detail-wrap">
            <div class="aop-base-info-wrap">
                <div v-for="item in configList " :key=item.key class="aop-base-info">
                    <div class="aop-detail-item">
                        <div v-if=item.label class="aop-item-label">{{item.label}}</div>
                        <div class="aop-item-value">
                            <div :class=item.class>
                                {{item.type ?
                                setTime(detail[item.key]) :
                                item.optionType?detail[item.key] === 1 ? '公司':'学校': detail[item.key]}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="aop-detail-status">{{detail.operate_at  && `${detail.operate_at}` !== '0'? '已处理' : '未处理'}}</div>
            </div>
            <div class="aop-detail-info-wrap">
                <div v-for="item in baseInfo" :key=item.key class="aop-detail-item">
                    <div class="aop-item-label">{{item.label}}</div>
                    <div class="aop-item-value">
                        {{
                        item.type==='time'
                        ? setTime(detail[item.key]) : item.type === 'operator'
                        ?detail.operator? detail.operator[item.key] : '--': detail[item.key]}}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="detail.operate_at  && `${detail.operate_at}` === '0'" class="aop-detail-btn">
            <el-button class="black-btn aop-black-btn" @click="visible = true">处理</el-button>
        </div>
        <el-dialog top="12%" :close-on-click-modal="false" :visible.sync="visible" width="458px"
            class="add-dialog dialogTip">
            <div class="dialog-content">
                <div class="aop-dialog-title">您已处理了该订阅申请？</div>
            </div>
            <span slot="footer" class="dialog-footer pop-dialog">
                <el-button class="confirm-button" :loading="btnLoading" @click="submit()">确认</el-button>
                <el-button class="cancel-button" @click="visible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { formatDate } from 'ecoplants-lib';
import api from '../../../../api/customer';

export default {
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            loading: false,
            detail: {},
            visible: false,
            btnLoading: false,
            configList: [
                { label: '', key: 'name', value: '用户名称', class: 'aop-key-value' },
                { label: '', key: 'email', value: 'zhangyuying@ecoplants.tech@qq.com', class: 'aop-key-value' },
                { label: '手机号', key: 'mobile' },
                { label: '类型', key: 'organization_type', optionType: 'type' },
                { label: '创建时间', key: 'created_at', type: 'time' }
            ],
            baseInfo: [
                { label: '名称:', key: 'organization_name' },
                { label: '内容:', key: 'message' },
                { label: '处理时间:', key: 'operate_at', type: 'time' },
                { label: '处理人名称:', key: 'username', type: 'operator' },
                { label: '处理人邮箱:', key: 'email', type: 'operator' }
            ]
        };
    },
    /**
     * 初始化
     */
    mounted() {
        this.init();
    },
    methods: {
        /**
        *获取数据
        */
        init() {
            this.loading = true;
            const id = this.$route.query.id || 2930;
            api.subscribeDetail(id).then((resp) => {
                if (resp.data.code === 10200) {
                    // 处理数据
                    this.detail = resp.data.data;
                } else {
                    this.$$error(resp.data.message);
                }
                this.btnLoading = false;
            });
        },
        /**
        * 取消
        */
        back() {
            this.$router.push('/customers/subscription');
        },
        /**
         * 时间格式化
         * @param {*} val  值
         * @return{*}
         */
        setTime(val) {
            return formatDate(val * 1000, 'yyyy-MM-dd hh:mm:ss') || '--';
        },
        /**
         * 处理订阅
         */
        submit() {
            const data = {
                id: this.detail.id
            };
            this.btnLoading = true;
            try {
                api.updateSubscribeStatus(data).then((res) => {
                    if (res.data.code === 10200) {
                        setTimeout(() => {
                            this.init();
                            this.$$success('操作成功');
                            this.visible = false;
                            this.btnLoading = false;
                        }, 1500);
                    } else {
                        this.btnLoading = false;
                        this.$$error(res.data.message);
                    }
                });
            } catch (e) {
                this.btnLoading = false;
            }
        }
    }
};
</script>

<style lang="less">
@import "./detail.less";
</style>
